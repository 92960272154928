document.addEventListener("DOMContentLoaded", () => {
  $('[data-toggle="tooltip"]').tooltip();
  const passwordField = document.querySelector("input.password")

  if (passwordField) {
    const passwordVisibilityToggle = document.querySelector(".toggle-password")

    if(passwordVisibilityToggle) {
      passwordVisibilityToggle.addEventListener("click", () => {
        passwordVisibilityToggle.classList.toggle("fa-eye");
        passwordVisibilityToggle.classList.toggle("fa-eye-slash");

        if (passwordField.type === "password") {
          passwordField.type = "text";
        } else {
          passwordField.type = "password"
        }
      })
    }
  }
})
