$(document).ready(function() {
  $("#show-more-button").click(function() {
    $("#sidebar-body").animate({
      scrollTop: $("#sidebar-body")[0].scrollHeight
    }, 500);
  });

  function isElementVisible(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  hideOrShowMoreButton = () => {
    const $lastSectionItem = $(".arkefip-app-sidebar-navigation-section-item:last");
    const $showMoreButton = $("#show-more-button");

    if ($lastSectionItem[0] && isElementVisible($lastSectionItem[0])) {
      $showMoreButton.addClass('d-none');
    } else {
      $showMoreButton.removeClass('d-none');
    }
  }
  $("#sidebar-body").scroll(hideOrShowMoreButton);
  hideOrShowMoreButton();
});