$(function() {
  Clipboard = {
    copy: function(elem) {
      var content = $(elem).data("clipboard-copy");

      var tmp_input = $("<textarea>"); // <input> would strip new lines
      $(elem).append(tmp_input);
      tmp_input.val(content).select();
      document.execCommand("copy");
      tmp_input.remove();
    },
    notice: function(elem) {
      var text = $(elem).data('clipboard-copy-notice');
      if (!text) return;
      if (typeof(notie) !== "undefined") {
        notie.alert({ "text": text });
      } else {
        alert(text);
      }
    },
    init: function() {
      $('body').on('click', '[data-clipboard-copy]', function() {
        Clipboard.copy($(this));
        Clipboard.notice($(this));
        return false;
      })
    }
  }
  Clipboard.init();
});
