document.addEventListener("DOMContentLoaded", () => {
  const inputWrappers = document.querySelectorAll(".otp-attempt-inputs .form-group");

  inputWrappers.forEach(inputWrapper => {
    const input = inputWrapper.querySelector('input')
    const nextInputWrapper = inputWrapper.nextElementSibling;
    const prevInputWrapper = inputWrapper.previousElementSibling;

    input.addEventListener("input", function (e) {
      const target = e.target;
      const val = target.value;

      if (isNaN(val)) {
        target.value = "";
        return;
      }

      if (val != "") {
        if (nextInputWrapper) {
          nextInputWrapper.querySelector('input').focus();
        }
      }
    });

    input.addEventListener("keydown", function (e) {
        const target = e.target;
        const key = e.key.toLowerCase();

        if (key == "backspace" || key == "delete") {
          if (target.value != "") {
            target.value = "";
          } else {
            if (prevInputWrapper) {
              const prevTarget = prevInputWrapper.querySelector('input');
              prevTarget.focus();
              prevTarget.value = "";
            }
          }
          return;
        }
    });
  });
});
