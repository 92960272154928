document.addEventListener("DOMContentLoaded", () => {
  const autopopover = $('[data-auto-popover="1"]');
  if (autopopover.length) {
    autopopover.popover('show').popover({
      trigger: 'focus'
    });
    $('.bs-popover-auto').addClass("d-none");
    $('.bs-popover-auto').addClass("d-md-block");
    $('body').on('click', function (e) {
      $('.bs-popover-auto').remove();
    });
  }
});
