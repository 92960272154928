document.addEventListener('DOMContentLoaded', function () {
  const signUpForm = document.getElementById('userLoginForm');
  if (signUpForm) {
    document.getElementById('submitBtn').addEventListener('click', () => {
      grecaptcha.execute();
    })
  }

  var submitNewUserForm = function () {
    document.getElementById("userLoginForm").submit();
  };

window.submitNewUserForm = submitNewUserForm;
});
